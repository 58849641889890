<template>
  <div
    class=" flex w-full bg-img vx-row items-center justify-center "
  style="height:700px; "
  >
    <div class="sm:w-1/2 md:w-1/2 lg:w-3/4 sm:m-0 m-4 mycard">
      <vx-card>
        <div slot="no-body">
            
          <div
            class="vx-row no-gutter justify-center items-center mycard"
            style="background: transparent radial-gradient(closest-side at 50% 50%, #2753D9 0%, #2753D9 100%) 0% 0% no-repeat padding-box ;
                    box-shadow: -3px 0px 22px #00000029;
                      margin: -13px;
                    border-radius: 31px;
                    opacity: 1; "
          >
          
            <div class="vx-col align-center lg:block lg:w-3/4 sm:w-1/4 ">
            <div class="MyPopClose" >
                <feather-icon class="mt-5 " style="color:white" icon="XIcon" svgClasses="w-10 h-10 stroke-current" @click.stop="$emit('closePop')" />
            </div>
              <img
                src="@/assets/images/footer_logo.png"
                style="max-width:150px ;"
                alt="login"
                class="mx-auto mt-3"
              />
              <div class="mt-10 ;"  style="
                  font: normal normal 300 20px/21px Futura PT;
                       letter-spacing: 0px;
                        color: #FFFFFF;" >
                <p class="m-4 " >{{$t("BankName") }} :   <span style="color:#eaff4d" class="ml-3"> {{Model.BankName}} </span></p>
                <p class="m-4">{{$t("BranchAddress") }}:   <span style="color:#eaff4d" class="ml-3"> {{Model.BranchAddress}} </span></p>
                <p class="m-4">{{$t("AccountNumber") }}:   <span style="color:#eaff4d" class="ml-3"> {{Model.BankName}} </span> </p>
          
                <p class="mb-12 ml-4">{{$t("IBAN") }} : <span style="color:#eaff4d" class="ml-3"> {{Model.BankName}} </span></p>
              </div>
       
      
            </div>
            <!-- <div
              class="vx-col sm:w-1/4 md:w-full lg:w-1/2 d-theme-dark-bg  "
              style="border-radius: 31px 31px 31px 31px;"
            ></div> -->
           
          </div>
          
        </div>
        
      </vx-card>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    Model: {
      type: Object,
      default: () => {}
    }
  },
}
</script>
<style lang="scss">

.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
  .background {
    color: rgb(255, 9, 9);
  }
}
.mycard .vx-card {
  background: unset;
  // padding: 0px !important;
}
</style>
