<template>
  <div id="extras">
    <table style="width: 60%; background-color: white; margin: auto !important">
      <tr class="HeaderTableColor">
        <th class="h-24 text-center" style="width: 40%">
          {{ $t("ExtraServices") }}
        </th>
        <th class="h-24 text-center" style="width: 30%">
          {{ $t("Count") }}
        </th>
        <th class="h-24 text-center" style="width: 30%">
         
          {{ $t("Price") }}
        </th>
      </tr>
      <tr
        class="text-xl h-24 p-1 m-1 text-center"
        v-for="item in ExtraServices"
        :key="item.ID"
      >
        <td class="text-center">
          <vs-checkbox v-model="item.isAddSurgry">{{ item.Name }}</vs-checkbox>
        </td>
        <td class="flex justify-center">
          <vs-input
            class="w-full lg:w-1/2 mt-4"
            type="number"
            :min="1"
            :disabled="item.Selected"
            v-model="item.Count"
          >
          </vs-input>
        </td>
        <!--{{ item.Currency.Symbol }}-->
        <td>{{ item.Price * (item.Count == 0 ? 1 : item.Count) }} USD</td>
      </tr>

      <tr>
        <td class="text-left"></td>
        <td class="text-center">
          <vs-button
            class="text-lg mx-1"
            @click="AddExtraService(ExtraServices)"
            >{{ $t("Confirm") }}</vs-button
          >
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import moduleExtraService from "@/store/settings/extraService/moduleExtraService.js";

export default {
  data() {
    return {
      checkBox1: true,
      checkBox2: true,
    };
  },
  computed: {
    ExtraServices() {
      return this.$store.state.ExtraServiceList.ExtraServices;
    },
  },
  created() {
    if (!moduleExtraService.isRegistered) {
      this.$store.registerModule("ExtraServiceList", moduleExtraService);
      moduleExtraService.isRegistered = true;
    }
    var surgryID = this.$route.params.ID;
    //,surgryID
    this.$store.dispatch("ExtraServiceList/GetAllExtraServices", surgryID);
    debugger;
  },

  components: {},
  methods: {
    AddExtraService(lists) {
      debugger;
      var ExtraList = [];
      for (var i = 0; i < lists.length; i++) {
        var model = {};
        model.patientReservationSurgeryID = this.$route.params.ID;
        model.ExtraServiceID = lists[i].ID;
        model.currencyID = lists[i].CurrencyID;
        model.price = lists[i].Price;
        model.Count = lists[i].Count;
        model.Selected = lists[i].isAddSurgry;
        ExtraList.push(model);
      }

      this.$store
        .dispatch(
          "ExtraServiceList/SavePatientReservationSurgeryExtraService",
          ExtraList
        )
        .then((res) => {
          window.showSuccess(res.data.message);
          this.$vs.loading.close();

          this.ExtraPaymentPrepair();
        })
        .catch((err) => {
          window.showError(err.response.data.message);
          this.$vs.loading.close();
        });
    },
    ExtraPaymentPrepair() {
      var obj = { ID: this.$route.params.ID };
      obj.FrontUrl = window.location.origin
      this.$store
        .dispatch("ExtraServiceList/ExtraPaymentPrepair", obj)
        .then((res) => {
          this.$emit("closePop");
          window.location.href  = res.data.Data.transaction.url;
          // if (res.data.Data.amount && res.data.Data.amount > 0) {
          //   document.getElementById("amount").value = res.data.Data.amount;
          //   document.getElementById("customer_email").value =
          //     res.data.Data.customer_email;
          //   document.getElementById("signature").value =
          //     res.data.Data.signature;
          //   document.getElementById("merchant_reference").value =
          //     res.data.Data.merchant_reference;
          //   document.getElementById("paymentForm").submit();
          // }
        });
    },
  },
};
</script>
<style>
#extras table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
  border: solid #8d777729 1px !important;
}

#extras td,
#extras td {
  border-left: solid #8d777729 1px !important;
  border-top: solid #00000029 1px !important;
}

#extras th {
  border-top: none;
}

#extras th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}

#extras th:last-child {
  border-top-right-radius: 36px;
}
#extras td:first-child,
#extras th:first-child {
  border-left: none;
}
</style>

<style>
.material-icons {
  font-family: "Material Icons" !important;
}
.material-icons :lang(en) {
  font-family: unset;
}

.mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
.login-tabs-container {
  min-height: 505px;
}
.con-tab {
  padding-bottom: 14px;
}

.con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}
#extras .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
.head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
</style>
