/*=========================================================================================
  File Name: moduleExtraServiceActions.js
  Description: ExtraService Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddExtraService({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/ExtraService/AddExtraService", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllExtraServices({ commit },surgryID) {
    debugger;
    return new Promise((resolve, reject) => {
      //GetAllExtraServices
      //GetAllExtraServicesUnionPatientReservationSurgrey?ID="+surgryID)
      axios.get("api/ExtraService/GetAllExtraServicesUnionPatientReservationSurgrey?surgryID="+surgryID)
        .then((response) => {
          commit('SET_ExtraService', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateExtraService({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/ExtraService/UpdateExtraService", item)
        .then((response) => {
          commit('UPDATE_ExtraService', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetExtraService({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/ExtraService/GetExtraService", itemid)
        .then((response) => {
          commit('UPDATE_ExtraService', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteExtraService({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/ExtraService/DeleteExtraService?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  AddPatientReservationSurgeryExtraService(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/API/PatientReservationSurgeryExtraService/AddPatientReservationSurgeryExtraService",
          item
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SavePatientReservationSurgeryExtraService(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/API/PatientReservationSurgeryExtraService/SavePatientReservationSurgeryExtraService",
          item
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  ExtraPaymentPrepair(context, item)
  {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/API/PatientReservationSurgeryExtraService/ExtraPaymentPrepair",
          item
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DeletePatientReservationSurgeryExtraService({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.delete("api/PatientReservationSurgeryExtraService/DeletePatientReservationSurgeryExtraService?ID="+item)
        .then((response) => {

          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
 
}
