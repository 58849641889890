<template>
  <div id="patientSR" class="flex items-center justify-center">
    <table
      style="background-color: white; margin: auto !important"
      class="text-center shadow-md m-3 bg-white w-full"
    >
      <tr class="HeaderTableColor">
        <th class="h-32 text-center">{{ $t("TestsAndReports") }}</th>
        <th class="h-32 text-center">{{ $t("Description") }}</th>

        <th class="h-32 text-center">
          
          {{ $t("UploadDate") }}
        </th>
        <!-- <th class="  h-32 text-center">Doctors</th> -->
      </tr>
      <tr
        :data="tr"
        :key="indextr"
        v-for="(tr, indextr) in GetSurgeryDocuments"
        class="text-xl h-24 p-1 m-1 text-center"
      >
        <td>
          <span>{{ tr.ReportTypeName }} </span>
        </td>
        <td>
          <span>{{ tr.ReportTypeName }} </span>
        </td>
        <td>
          <span v-if="tr.UploadDate">{{ tr.UploadDate.split("T")[0] }}</span>
        </td>
        <!-- <td>
              <img
              v-if="tr.DoctorImage"
                :src="baseURL + tr.DoctorImage"
                alt="user-img"
                width="70"
                height="70"
                class="rounded-full shadow-md cursor-pointer block mx-auto"
              />
              <p>Dr/{{ tr.DoctorName }}</p>
            </td> -->
      </tr>
    </table>
  </div>
</template>
<script>
import { domain, mainSetting } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";

export default {
  data() {
    return {
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
    };
  },
  computed: {
    GetSurgeryDocuments() {
      debugger
      return this.$store.state.patientList.SurgeryDocuments;
    },
  },
  methods: {},
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
  },
};
</script>
<style>
.patientSR table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
}
.patientSR th,
td {
  border: 1px solid rgb(255, 255, 255);
  border-collapse: collapse;
}
.mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
.login-tabs-container {
  min-height: 505px;
}
.con-tab {
  padding-bottom: 14px;
}

.con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}
#patientSR .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
.head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
.patientSR [dir] th,
.patientSR [dir] td {
  border: 1px solid #cfcfcf !important;
}
</style>
