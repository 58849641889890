

<template>
  <section class="invoice-preview-wrapper">

   

    <b-row
      v-if="Model"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <imageLazy
                      :imageStyle="{ 'border-radius': '20px','width': '120px','height':'120px'}"
                      :imageClass="'rounded-full'"
                      :src="(baseURL +  Model.HospitalImage)"
                      placeHolderType="hospital"
                    />
                  <h3 class="text-primary mt-1 invoice-logo">
                   
                    {{ Model.HospitalName }}
                  </h3>
                  <star-rating
              class="m-3"
              :max-rating="5"
              :star-size="30"
              v-model="Model.HospitalRate"
              :read-only="true"
              :show-rating="false"
            ></star-rating>

                </div>

                <h4 class="text-primary invoice-logo">  {{ Model.SurgeryName }}</h4>
                <p class="card-text mb-25"
                  v-if="Model.SurgeryDate">
                {{ Model.SurgeryDate.split("T")[0] }}
              </p>
              <p class="card-text mb-25" v-else>{{ $t("Date") + " " }}{{ $t("NotSetYet") }}</p>
               
                <p  v-if="
                  Model.DoctorHospitalSurgerys &&
                  Model.DoctorHospitalSurgerys[0].DoctorID
                " class="card-text mb-25">
                   <imageLazy
                      :imageStyle="{ 'border-radius': '20px','width': '120px','height':'120px'}"
                      :imageClass="'rounded-full'"
                      :src="baseURL + Model.DoctorHospitalSurgerys[0].DoctorImage"
                      placeHolderType="doctor"
                    />
                    <u class="card-text mb-25">
                  Dr.{{ Model.DoctorHospitalSurgerys[0].DoctorName }}
                    </u>
                    <a
                  style="border-radius: 10px; width: 50px; height: 40px"
                  class="p-3 mt-5 text-sm text-white bg-primary"
                  :href="baseURL + Model.DoctorHospitalSurgerys[0].CVPath"
                  target="_blank"
                >
                  C.V.
                </a>
                </p>
                <p  v-else class="card-text mb-0">
                  {{ $t("Doctor") + " " }} {{ $t("NotSetYet") }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 style="font-weight:bold" class="invoice-title">
                  {{ $t("deposit") }} : {{ Model.ProcessingFees + Model.VAT }} USD
                </h4>
                <div class="invoice-date-wrapper">
                  <p style="font-weight:bold" class="invoice-date text-danger">
                    <span v-if="Model.PaymentStatusID != 5">
                {{ $t("RemainingSurgeryAmount") }}</span
              >
              <span style="font-weight:bold" v-else>{{ $t("SurgeryPrice") }}</span> :
              {{ Model.Price }} USD
                  </p>
                  <p style="font-weight:bold"  v-if="Model.ExtraServiceTotalPrice" class="invoice-date">
                    {{ $t("ExtraServiceTotalPrice") }} :
              {{ Model.ExtraServiceTotalPrice }} USD
                  </p>

                  <p  style="font-weight:bold"   v-if="Model.ExtraServiceUnpayedPrice" class="invoice-date">
                    {{ $t("UnPayedExtraService") }} :
              {{ Model.ExtraServiceUnpayedPrice }} USD
                  </p>
                  <p style="font-weight:bold"  class="invoice-date text-primary">
              {{ $t("PaymentStatus") }} :
              <span style="font-weight:bold" v-if="Model.PaymentStatusID == 1">
                {{ $t("PendingProcessFees") }}
              </span>
              <span style="font-weight:bold " class="text-primary" v-else> {{ Model.PaymentStatusName }} </span>
              -
              <span style="font-weight:bold" v-if="Model.PaymentStatusID == 7">{{
                $t("PendingRmainSurgeryAmount")
              }}</span>
            </p>

            <p style="font-weight:bold"  class="invoice-date text-primary">
              {{ $t("Status") }} : {{ Model.StatusName }}
            </p>
            <p
            v-if="
              Model.SurgeryDate && (Model.StatusID == 1 || Model.StatusID == 2)
            "
          >
            If you have issue with scheduled date,</p>
            <p
            v-if="
              Model.SurgeryDate && (Model.StatusID == 1 || Model.StatusID == 2)
            "
          > you can
            <a href="mailto:" style="color: #008abe"> contact us </a> to resolve
            it.
          </p>

                </div>
               
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <b-card-body
           
            class="invoice-padding pt-0 m-4"
          >
            <b-row class="invoice-spacing">

              <!-- Col: SurgeryIncludedItems To -->
              <b-col
              v-if="(Model.SurgeryIncludedItems && Model.SurgeryIncludedItems.length>0)"
                cols="12"
                xl="6"
                class="p-0"
              >
              <h3 class="text-primary invoice-logo">
                  {{ $t("SurgeryTreatmentIncludes") }}
                </h3>
                <b-row
            class="vx-row ml-10"
            v-for="item in (Model.SurgeryIncludedItems)"
            :key="item.ID"
          >
          <p class="circle primaryBackColor mt-1 mr-2"></p>  <span class=" mt-1">{{ item.Name }}</span>
            <!-- <h5>
              <p>{{ item.Name }}</p>
            </h5> -->
          </b-row>
              </b-col>

              <!-- Col: SurgeryExcludedItems -->
              <b-col
              v-if="(Model.SurgeryExcludedItems && Model.SurgeryExcludedItems.length>0)"
                xl="6"
                cols="12"
                class="p-0"
              >
                  <h3 class="text-primary invoice-logo">
                    {{ $t("SurgeryTreatmentNotIncludes") }}
                  </h3>
                  <b-row
                  class="vx-row ml-10"
            v-for="item in Model.SurgeryExcludedItems"
            :key="item.ID"
          >
          <p class="circle primaryBackColor mt-1 mr-2"></p>  <span class=" mt-1">{{ item.Name }}</span>
          </b-row>
              </b-col>
            </b-row>
          </b-card-body>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: showConfirmDialog -->
          <b-button
          v-if="Model.StatusID != 3 && Model.StatusID != 5"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="danger"
            class="mb-75"
            block
            @click="showConfirmDialog = true"
          >
          {{ $t("CancelSurgeryRequest") }}
          </b-button>
          <div v-if="Model.StatusID != 3 && Model.StatusID != 5">
          <!-- Button: MedicalDocumentation -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="
                ShowPatientSurgeryReport = true;
                GetSurgeryDocuments();
              "
          >
          {{ $t("MedicalDocumentation") }}
          </b-button>

          <!-- Button: TravelArrangements -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="showTravelarrangements = true"
          >
          {{ $t("TravelArrangements") }}
          </b-button>

          <!-- Button: ExtraServices -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="showExtras = true"
          >
          {{ $t("ExtraServices") }}
          </b-button>

          <!-- Button: Add PayProcessingFees -->
          <b-button
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="PayProcessNow"
            v-if="(Model.PaymentStatusID == 1)"
          >
          {{ $t("PayProcessingFees") }}   
               </b-button>
          <!-- Button: Add PayExtraServices -->

               <b-button
               v-if="
                Model.ExtraServiceUnpayedPrice &&
                Model.ExtraServiceUnpayedPrice > 0
              "
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="PayExtraServices"
          >
          {{ $t("PayExtraServices") }}   
               </b-button>

                <!-- Button:  HospitalBankTransferDetails -->

                <b-button
                v-if="
                Model.IsAllowHospitalBankTransfare &&
                Model.PaymentStatusID == 7 &&
                Model.StatusID > 1
              "
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="ShowBankDetails = true"
          >
          {{ $t("HospitalBankTransferDetails") }}   
               </b-button>

               <div
              v-if="
                !Model.IsAllowHospitalBankTransfare &&
                Model.PaymentStatusID == 7 &&
                Model.StatusID > 1
              "
            >
            <!-- PayRemainingSurgeryAmount -->
            <b-button
            v-if="Model.IsBeFord"
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="PayNow"
          >
          {{ $t("PayRemainingSurgeryAmount") }}   
               </b-button>


                <!-- PayRemainingSurgeryAmount -->
            <b-button
            v-if="Model.IsBeFord"
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="ShowBankDetails = true"
          >
          {{ $t("DocliniaBankTransferDetails") }}   
               </b-button>

          </div>
          <p v-if="Model.StatusID == 1 && Model.PaymentStatusID > 1">
              {{ $t("SurgeryPayRemainNote") }}
            </p>
            <h4
              class="mt-5 warning"
              v-if="Model.StatusID == 1 && Model.PaymentStatusID == 1"
            >
              {{ $t("SurgeryProcessingNote") }}
            </h4>
               </div>
        </b-card>
      </b-col>
    </b-row>
    <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
    <vs-popup  title="" :active.sync="showExtras">
        <Extras v-if="showExtras" @closePop="CloseExtraPop"></Extras>
      </vs-popup>

      <vs-popup title="" :active.sync="showTravelarrangements">
        <Travelarrangements
          @closePop="showTravelarrangements = false"
          v-if="showTravelarrangements"
        >
        </Travelarrangements>
      </vs-popup>

      <vs-popup title="" :active.sync="ShowPatientSurgeryReport">
        <PatientSurgeryReport
          @closePop="ShowPatientSurgeryReport = false"
          v-if="ShowPatientSurgeryReport"
        ></PatientSurgeryReport>
      </vs-popup>

      <vs-popup title="" :active.sync="ShowPatientSurgeryReport">
        <PatientSurgeryReport
          @closePop="ShowPatientSurgeryReport = false"
          v-if="ShowPatientSurgeryReport"
        ></PatientSurgeryReport>
      </vs-popup>

      <vs-popup title="" :active.sync="ShowBankDetails">
        <BanKDetails
          @closePop="ShowBankDetails = false"
          v-if="ShowBankDetails"
          :Model="Model.BankingDetails"
        ></BanKDetails>
      </vs-popup>

      <vs-popup title="" :active.sync="showConfirmDialog">
        <confirmDialog
          @closePop="showConfirmDialog = false"
          @confirm="CancelSurgeryRequest"
          :showCancelControls="true"
          :modelCancel="modelCancel"
          v-if="showConfirmDialog"
          :message="$t('surgeryCancelConfirm')"
        ></confirmDialog>
      </vs-popup> 
  </section>
</template>
<script>
import confirmDialog from "@/components/confirmDialog.vue";
import imageLazy from "@/components/image_lazy.vue";
import { domain, mainSetting } from "@/gloabelConstant.js";
import HospitalModule from "@/store/hospital/moduleHospital.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleExtraService from "@/store/settings/extraService/moduleExtraService.js";
import BanKDetails from "@/views/Patient/PatientSurgeries&Treatments/BanKDetails.vue";
import Extras from "@/views/Patient/PatientSurgeries&Treatments/Extras.vue";
import Travelarrangements from "@/views/Patient/PatientSurgeries&Treatments/Travelarrangements.vue";
import PatientSurgeryReport from "@/views/Patient/PatientSurgeryReport.vue";
import { BAlert, BButton, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTableLite } from 'bootstrap-vue';
import StarRating from "vue-star-rating";
import point from "../../../components/point.vue";
export default {
  data() {
    return {
      EnglishLan: false,
      showTravelarrangements: false,
      ShowBankDetails: false,
      showExtras: false,
      baseURL: domain,
      modelCancel: {},
      ShowPatientSurgeryReport: false,
      uploadUrl: domain + "api/Common/UploadFile",
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      Model: {},
      showConfirmDialog: false,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    imageLazy,
    confirmDialog,
    Extras,
    // Travelarrangements,
    StarRating,
    point,
    Travelarrangements,
    BanKDetails,
    PatientSurgeryReport,
  },

  methods: {
    PayExtraServices() {
      this.Model.FrontUrl = window.location.origin
      this.$store
        .dispatch("ExtraServiceList/ExtraPaymentPrepair", this.Model)
        .then((res) => {
          window.location.href  = res.data.Data.transaction.url;
          // if (res.data.Data.amount && res.data.Data.amount > 0) {
          //   document.getElementById("amount").value = res.data.Data.amount;
          //   document.getElementById("customer_email").value =
          //     res.data.Data.customer_email;
          //   document.getElementById("signature").value =
          //     res.data.Data.signature;
          //   document.getElementById("merchant_reference").value =
          //     res.data.Data.merchant_reference;
          //   document.getElementById("paymentForm").submit();
          // }
        });
    },
    CloseExtraPop() {
      this.showExtras = false;
      this.getsurgeryDetail(this.Model.ID);
    },
    PayNow() {
      debugger;
      this.$vs.loading();
      this.Model.FrontUrl = window.location.origin
      this.$store
        .dispatch("patientList/SurgeryPaymentDatail", this.Model)
        .then((res) => {
           window.location.href  = res.data.Data.transaction.url;
          // this.$vs.loading.close();
          // document.getElementById("amount").value = res.data.Data.amount;
          // document.getElementById("customer_email").value =
          //   res.data.Data.customer_email;
          // document.getElementById("signature").value = res.data.Data.signature;
          // document.getElementById("merchant_reference").value =
          //   res.data.Data.merchant_reference;
          // document.getElementById("paymentForm").submit();
        });
    },
    PayProcessNow() {
      this.$vs.loading();
this.Model.FrontUrl = window.location.href
      this.$store
        .dispatch("patientList/ProcessingPaymentDatail", this.Model)
        .then((res) => {
          debugger
          this.$vs.loading.close();
          window.location.href  = res.data.Data.transaction.url;
          // document.getElementById("amount").value = res.data.Data.amount;
          // document.getElementById("customer_email").value =
          //   res.data.Data.customer_email;
          // document.getElementById("signature").value = res.data.Data.signature;
          // document.getElementById("merchant_reference").value =
          //   res.data.Data.merchant_reference;
          // document.getElementById("paymentForm").submit();
        });
    },
    back() {
      this.$router.go(-1);
    },
    CancelSurgeryRequest() {
      var reservedSurgery = {
        PatientReservationSurgeryID: this.Model.ID,
        StatusID: 3,
        CancellationReasons: this.modelCancel.CancellationReasons,
      };
      this.$store
        .dispatch(
          "HospitalList/ChangePatientReservationSurgeryStatus",
          reservedSurgery
        )
        .then((res) => {
          debugger;
          this.$vs.notify({
            title: this.$t("success"),
            text: res.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.showConfirmDialog = false;

          this.getsurgeryDetail(this.Model.ID);
        })
        .catch((err) => {
          debugger;
          this.$vs.notify({
            title: this.$t("Error"),
            text: err.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    getPatientReservationSurgery(id) {
      var model = {};
      model.PatientId = id;
      model.StatusID = 2; //confirmed
      this.$vs.loading();

      this.$store
        .dispatch("patientList/getPatientReservationSurgery", model)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    GetSurgeryDocuments() {
      this.$store.dispatch(
        "patientList/GetSurgeryDocuments",
        this.$route.params.ID
      );
    },
    getsurgeryDetail(ID) {
      this.$store
        .dispatch("patientList/GetPatientReservationSurgery", ID)
        .then((res) => {
          debugger;
          if (res.status == 200) {
            debugger;
            this.Model = res.data.Data;
            if (
              this.Model.HospitalRate == null ||
              this.Model.HospitalRate == undefined ||
              this.Model.HospitalRate == "NaN"
            ) {
              this.Model.HospitalRate = 0;
            }
          }
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
  
    if (!moduleExtraService.isRegistered) {
      this.$store.registerModule("ExtraServiceList", moduleExtraService);
      moduleExtraService.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }

    const ID = this.$route.params.ID;
    const StatusID = this.$route.params.StatusID;

    this.getsurgeryDetail(ID);
    if (StatusID) {
      if (StatusID != 14) {
        window.showError(this.$t("Paymentfail"));
      }
    }
  },

  
};
</script>
<style >
.vs-table--not-data {
  display: none;
}
</style>
<style scoped>
#surgeryProfile th:last-child {
  border-top-right-radius: 0px;
}
#surgeryProfile th:first-child {
  border-top-left-radius: 0px;
}
</style>


<style>
#surgeryProfile .popup-example {
  width: 50%;
  height: 20%;
}
#surgeryProfile table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
  border: 1px solid black;
}
#surgeryProfile th,
#surgeryProfile td {
  border: 1px solid #8d777729;
  border-collapse: collapse;
}

#surgeryProfile .background {
  color: rgb(255, 9, 9);
}
.scroll {
  overflow-x: auto !important;
}
</style>
