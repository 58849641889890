
<template>
  <div
  class="flex w-full bg-img vx-row no-gutter items-center justify-center "
>
  <div class="m-4 ">
    <vx-card>
      <div slot="no-body">

        <b-row
          class="no-gutter justify-center items-center mycard"
          style="background: transparent radial-gradient(closest-side at 50% 50%, #2753D9 0%, #2753D9 100%) 0% 0% no-repeat padding-box ;
                  box-shadow: -3px 0px 22px #00000029;
                    margin: -13px;
                  border-radius: 31px;
                  opacity: 1; "
        >

          <b-col class=" text-center ">
         
            <img
              src="@/assets/images/footer_logo.png"
              style="max-width:100px ; background-color:white"
              alt="login"
              class="mx-auto mt-3"
              
            />
            <div class="mb-5 mt-5">
              <p
                  style="text-align: center;
                  font: normal normal 300 20px/21px Futura PT;
                       letter-spacing: 0px;
                        color: #FFFFFF;"
                >
                  {{$t("sendMail")}}
                 <u> <a href="mailto:travel@doclinia.com" style="color: #FFFFFF;">{{"travel@doclinia.com"}}</a></u>
                  {{$t("TravelArrange")}}
                </p>
            </div>
          
          </b-col>
        </b-row>
      </div>
    </vx-card>
  </div>
</div>
</template>
<script>
import { BAlert, BButton, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTableLite } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  
}
</script>
<style lang="scss">

.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
  .background {
    color: rgb(255, 9, 9);
  }
}
.mycard .vx-card {
  background: unset;
  // padding: 0px !important;
}
</style>
